import React from "react";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { API, Auth } from 'aws-amplify';
import ManageAddEventDialog from './ManageAddEventDialog';
import ManageEditEventDialog from './ManageEditEventDialog';
import ManageUsersEventDialog from './ManageUsersEventDialog';

import CircularProgress from '@material-ui/core/CircularProgress';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TableHead from '@material-ui/core/TableHead';

import Avatar from '@material-ui/core/Avatar';
import EventIcon from '@material-ui/icons/Event';
import EditIcon from '@material-ui/icons/Edit';
import GroupIcon from '@material-ui/icons/Group';
import LinkIcon from '@material-ui/icons/Link';

import Moment from 'react-moment';

import { SITE } from '../env';

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}






const useStyles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  grow: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  loading: {
    alignContent: 'center',
    justifyContent: 'center',
    display: 'flex',
    marginTop: theme.spacing(8),
  },
  myContent: {
    //backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 2, 2),
  },
});

class ManageEvents extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      items: [],
      openAddEventDialog: false,
      openEditEventDialog: false,
      openUsersEventDialog: false,
      selectedEventEdit: false,
      selectedEventUsers: false,

      page: 0,
      rowsPerPage: 5,
      emptyRows: 0

    };
  }

  async componentDidMount() {
    this.fetchItems();
  }

  fetchItems = async () => {
    try {
      const data = await Auth.currentUserPoolUser();
      const userInfo = { username: data.username, ...data.attributes, };

      if (userInfo.email.substr(-10,10)!=='amazon.com'){
        this.props.history.push('/');
      }

      let apiName = 'Events';
      let path = 'manage/events/'+userInfo.sub; 
      let myInit = { 
          headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }, 
          response: true, 
      }
      API.get(apiName, path, myInit).then(response => {
          //console.log(response);
          this.setState({ items: response.data.events, isLoading: false })
          this.setState({ emptyRows: this.state.rowsPerPage - Math.min(this.state.rowsPerPage, response.data.events.length - this.state.page * this.state.rowsPerPage) });
      }).catch(error => {
          console.log(error);
      });      

    } catch (err) { 
      console.log('error: ', err) 
      this.props.history.push('/');
    }
  };

  handleClickOpenAddEventDialog = event => {
    this.setState({ openAddEventDialog: true });
  };

  handleCloseAddEventDialog = event => {
    this.fetchItems();
    this.setState({ openAddEventDialog: false });
  };

  handleClickOpenEditEventDialog = event => {
    this.setState({ selectedEvent: event, openEditEventDialog: true });
  };

  handleCloseEditEventDialog = event => {
    this.fetchItems();
    this.setState({ openEditEventDialog: false, selectedEventEdit: false });
  };

  handleClickOpenUsersEventDialog = event => {
    this.setState({ selectedEvent: event, openUsersEventDialog: true });
  };

  handleCloseUsersEventDialog = event => {
    this.setState({ openUsersEventDialog: false, selectedEventUsers: false });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage })
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    });
  };

  handleEdit = (value) => {
    this.setState({ selectedEventEdit: value, openEditEventDialog: true });
  }

  handleUsers = (value) => {
    console.log(value);
    this.setState({ selectedEventUsers: value, openUsersEventDialog: true });
  }
  
  render(){
    
    const { classes } = this.props;

    return (
      <Container className={classes.myContent}>
        <Toolbar>
          <Typography variant="h5" component="h1" gutterBottom>
              Manage Events
          </Typography>
          <div className={classes.grow} />
          <Button variant="contained" color="secondary" className={classes.margin} onClick={this.handleClickOpenAddEventDialog}>
            <AddIcon className={classes.extendedIcon} />
            Add Event
          </Button>
        </Toolbar>

        { this.state.isLoading ? (
          <div className={classes.loading} style={this.state.isLoading ? { } : {display: 'none' } }>
                <CircularProgress />
          </div>
        ):(

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                    <TableCell key='icon' align='left'>&nbsp;</TableCell>
                    <TableCell key='event_name' align='left'>
                      Event
                    </TableCell>
                    <TableCell key='edit' align='center'>Edit</TableCell>
                    <TableCell key='users' align='center'>Users</TableCell>
                    <TableCell key='link' align='center'>Link</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(this.state.rowsPerPage > 0
                  ? this.state.items.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                  : this.state.items
                ).map((row) => (
                  <TableRow key={row.event_code}>
                    <TableCell>
                      <Avatar>
                        <EventIcon />
                      </Avatar>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography variant="body1" gutterBottom>
                      {row.event_name} - (<strong>{row.event_code}</strong>)
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        <Moment format="MMM DD, YYYY - hh:mm a" date={row.event_datetime} />
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <IconButton aria-label="edit" onClick={ () => this.handleEdit(row) }>
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">
                      <IconButton aria-label="users" onClick={ () => this.handleUsers(row) }>
                        <GroupIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">
                      <IconButton aria-label="link" component="a" target="_blank" href={ SITE+"event/"+row.event_code }>
                        <LinkIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}

                {this.state.emptyRows > 0 && (
                  <TableRow style={{ height: 53 * this.state.emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    colSpan={6}
                    count={this.state.items.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>

        )}

        <ManageAddEventDialog open={this.state.openAddEventDialog} onClose={this.handleCloseAddEventDialog} />
        <ManageEditEventDialog selectedEvent={ this.state.selectedEventEdit } open={this.state.openEditEventDialog} onClose={this.handleCloseEditEventDialog} />
        <ManageUsersEventDialog selectedEvent={ this.state.selectedEventUsers } open={this.state.openUsersEventDialog} onClose={this.handleCloseUsersEventDialog} />
  
      </Container>
    );
  }
}

export default withStyles(useStyles)(ManageEvents);

