import React from "react";
import { withRouter, Switch, Route, Redirect, Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import CloudIcon from '@material-ui/icons/Cloud';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Hidden from '@material-ui/core/Hidden';
import Home from './components/Home';
import MyEvents from './components/MyEvents';
import ManageEvents from './components/ManageEvents';
import Login from './components/Login';
import Signup from './components/Signup';
import Forgot from './components/Forgot';
import Event from './components/Event';
import AppliedRoute from './utils/AppliedRoute';
import Amplify from 'aws-amplify';
import { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import './App.css';

import {createMuiTheme} from '@material-ui/core/styles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';

Amplify.configure(awsconfig);

const theme = createMuiTheme({
  palette: {
      primary: {
          main: '#232f3e',
      },
      secondary: {
          main: '#d45b07',
          light: '#e38b00',
          dark: '#bf2d06',
          contrastText: '#fff'
      },
  },
});

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <a target="_blank" rel="noopener noreferrer" href="https://github.com/aurbac" className="my_link" color="primary">
        AURBAC
      </a>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = theme => ({
  root: {
    flexGrow: 1,
  },
  footer: {
    margin: theme.spacing(4),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  myContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  grow: {
    flexGrow: 1,
  },
});


class App extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      auth: false, 
      anchorEl: null,
			isAuthenticated: false,
      open: null,
      toEvent: "",
      userInfo: null
    };
  }

	async componentDidMount() {
		try {
			if (await Auth.currentSession()) {
				this.userHasAuthenticated(true);
			}
		} catch (e) {
			if (e !== 'No current user') {
				console.log(e);
			}
		}
	}

	userHasAuthenticated = async authenticated => {
    this.setState({ isAuthenticated: authenticated });
    const data = await Auth.currentAuthenticatedUser();
    this.setState({ userInfo: data.attributes});
    //console.log(data.attributes);
  };
  
	setToEvent = event_code => {
		this.setState({ toEvent: event_code });
  };
  
	handleLogout = async event => {
		await Auth.signOut();
    this.userHasAuthenticated(false);
    this.setState({ open: false, userInfo: null });
    this.props.history.push('/login'); 
	};

  handleChange = (event) => {
    this.setState({ auth: false });
    //setAuth(event.target.checked);
  };

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget })
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
		const childProps = {
      userInfo: this.state.userInfo,
			isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      toEvent: this.state.toEvent,
      setToEvent: this.setToEvent,
		}

    return (

      <div className={classes.root}>
          <MuiThemeProvider theme={theme}>
          <AppBar position="static">
            <Toolbar>
              <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" component={Link} to={'/'}>
                <CloudIcon />
              </IconButton>
              <Hidden only={['xs']}>
                <Typography variant="h6" className={classes.title}>
                  AWS Events
                </Typography>
              </Hidden>
              <div className={classes.grow} />
              {this.state.userInfo!=null && (
                <Typography >
                  Hello, <strong>
                    {this.state.userInfo.name ? this.state.userInfo.name : this.state.userInfo.email }
                  </strong>
                </Typography>
              )}
              {this.state.isAuthenticated ? (
                <div>
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={this.handleMenu}
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={this.state.open}
                    onClose={this.handleClose}
                  >
                    <MenuItem onClick={this.handleClose} component={Link} to={'/my-events'}>My Events</MenuItem>
                    <MenuItem onClick={this.handleLogout}>Sign Out</MenuItem>
                  </Menu>
                </div>
              ) : (
                <div>
                  <Button color="inherit" component={Link} to={'/login'} color="secondary">Sign In</Button>
                  <Button color="inherit" component={Link} to={'/signup'}>Create Account</Button>
                </div>
              )}
            </Toolbar>
          </AppBar>
          
          <React.Fragment>
            <CssBaseline />
              <Switch>
                <AppliedRoute path="/" exact component={Home} props={childProps} />
                <AppliedRoute path="/login" exact component={Login} props={childProps} />
                <AppliedRoute path="/signup" exact component={Signup} props={childProps} />
                <AppliedRoute path="/forgot-password" exact component={Forgot} props={childProps} />
                <AppliedRoute path="/event/:event_code" exact component={Event} props={childProps} />
                <AppliedRoute path="/my-events" exact component={MyEvents} props={childProps} />
                <Route path="/manage/events" exact component={ManageEvents} />
                <Redirect to="/"/>
              </Switch>
          </React.Fragment>

          {/* Footer */}
          <footer className={classes.footer}>
            <Typography variant="h6" align="center" gutterBottom>
            </Typography>
            <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
              This is NOT an official AWS website and has no affiliation with Amazon Web Services.
            </Typography>
            <Copyright />
          </footer>
          {/* End footer */}
        </MuiThemeProvider>
      </div>
    
    );
  }
}

export default withRouter(withStyles(useStyles)(App));
