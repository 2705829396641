import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Analytics from '@aws-amplify/analytics';
import { BrowserRouter as Router } from 'react-router-dom';
import Amplify from 'aws-amplify';
import { APP_AWS_REGION, EVENTS_API_ENDPOINT, BUCKET_FILES } from './env';



Amplify.configure({
  API: {
      endpoints: [
          {
              name: "Events",
              endpoint: EVENTS_API_ENDPOINT,
              region: APP_AWS_REGION
          }
      ]
  },
  Storage: {
    AWSS3: {
        bucket: BUCKET_FILES, //REQUIRED -  Amazon S3 bucket
        region: APP_AWS_REGION, //OPTIONAL -  Amazon service region
    }
}
});

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
Analytics.autoTrack('session', {
  enable: true,
  attributes: {},
});

Analytics.autoTrack('pageView', {
  // REQUIRED, turn on/off the auto tracking
  enable: true,
  eventName: 'pageView',
  // OPTIONAL, by default is 'multiPageApp'
  // you need to change it to 'SPA' if your app is a single-page app like React
  type: 'SPA',
  getUrl: () => {
      return window.location.origin + window.location.pathname;
  }
});

Analytics.autoTrack('event', {
  // REQUIRED, turn on/off the auto tracking
  enable: true,
  // OPTIONAL, events you want to track, by default is 'click'
  events: ['click'],
  // OPTIONAL, the prefix of the selectors, by default is 'data-amplify-analytics-'
  // in order to avoid collision with the user agent, according to https://www.w3schools.com/tags/att_global_data.asp
  // always put 'data' as the first prefix
  selectorPrefix: 'data-amplify-analytics-'
});