import React from "react";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import { Link } from "react-router-dom";
import { Analytics, API, Auth } from 'aws-amplify';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TableHead from '@material-ui/core/TableHead';

import Avatar from '@material-ui/core/Avatar';
import EventIcon from '@material-ui/icons/Event';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Moment from 'react-moment';

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}




const useStyles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  loading: {
    alignContent: 'center',
    justifyContent: 'center',
    display: 'flex',
    marginTop: theme.spacing(4),
  },
  downButton: {
    alignContent: 'center',
    justifyContent: 'center',
    display: 'flex',
    marginTop: theme.spacing(4),
  },
  myContent: {
    //backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 2, 2),
  },
});

class MyEvents extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isAmazon: false,
      items: [],
      page: 0,
      rowsPerPage: 5,
      emptyRows: 0,
      openRemoveConfirmation: false,
      eventId: null,
      userId: null
    };
  }

  componentDidMount() {
    this.loadEvents();
  }

  async loadEvents() {
    try {
			if (await Auth.currentSession()) {
        const data = await Auth.currentAuthenticatedUser();
        const userInfo = data.attributes;
        //console.log(`Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`);
        if (userInfo){
          try {
            if (userInfo.email.substr(-10,10)==='amazon.com'){
              this.setState({ isAmazon: true });
            }
            this.setState({ userId: userInfo.sub });
            let apiName = 'Events';
            let path = 'events/'+userInfo.sub;
            //console.log(path);
            let myInit = {
                headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }, 
                response: true,
            }
            API.get(apiName, path, myInit).then(response => {
                this.setState({ items: response.data.events, isLoading: false });
            }).catch(error => {
                console.log(error.response);
            });
          } catch (err) { 
            console.log('error: ', err) 
            this.props.history.push('/');
          }
        }else{
          this.props.history.push('/');
        }
			}
		} catch (e) {
      this.props.history.push('/');
		}
  }


  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage })
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    });
  };

  handleClickOpenRemoveConfirmation = (event, event_id) => {
    this.setState({ openRemoveConfirmation: true, eventId: event_id });
  };

  handleCloseRemoveConfirmationCancel = () => {
    this.setState({ openRemoveConfirmation: false });
  };

  handleCloseRemoveConfirmationOk = async () => {
    this.setState({ openRemoveConfirmation: false });
    try {
      let apiName = 'Events';
      let path = 'events/remove/'+this.state.userId+'/'+this.state.eventId; 
      let myInit = { 
          headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }, 
          response: true, 
      }
      API.post(apiName, path, myInit).then(response => {
        this.loadEvents();
      }).catch(error => {
        console.log(error.response);
      });

      Analytics.record({
        name: 'eventRemoved', 
        attributes: { event_id: this.state.eventId+"" }
      }).then( (evt) => {
        //console.log("Event Submitted" + JSON.stringify(evt));
      });

    } catch (err) { 
      console.log('error: ', err) 
    }

  };

  render(){

    const { classes } = this.props;

    return (
      <Container className={classes.myContent}>
        <Toolbar>
          <Typography variant="h5" component="h1" gutterBottom>
              My Events
          </Typography>
        </Toolbar>


        { this.state.isLoading ? (
          <div className={classes.loading} style={this.state.isLoading ? { } : {display: 'none' } }>
                <CircularProgress />
          </div>
        ):(

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                    <TableCell key='icon' align='left'>&nbsp;</TableCell>
                    <TableCell key='name' align='left'>
                      Event
                    </TableCell>
                    <TableCell key='details' align='center'>Details</TableCell>
                    <TableCell key='remove' align='center'>Remove</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(this.state.rowsPerPage > 0
                  ? this.state.items.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                  : this.state.items
                ).map((row) => (
                  <TableRow key={row.name}>
                    <TableCell>
                      <Avatar>
                        <EventIcon />
                      </Avatar>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography variant="body1" gutterBottom>
                      {row.event_name} - (<strong>{row.event_code}</strong>)
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        <Moment format="MMM DD, YYYY - hh:mm a" date={row.event_datetime} />
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <IconButton aria-label="link" component={Link} to={ "event/"+row.event_code }>
                        <VisibilityIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">
                      { row.event_open ? (
                      <IconButton onClick={(event) => this.handleClickOpenRemoveConfirmation(event, row.event_id)}>
                        <DeleteForeverIcon />
                      </IconButton>                   
                      ) : (
                      <IconButton disabled>
                        <DeleteForeverIcon />
                      </IconButton>
                      ) }
                    </TableCell>
                  </TableRow>
                ))}

                {this.state.emptyRows > 0 && (
                  <TableRow style={{ height: 53 * this.state.emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    colSpan={6}
                    count={this.state.items.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>

        )}

        <Button color="secondary" component={ Link } to={'/manage/events'} className={classes.downButton} style={this.state.isAmazon ? { } : {display: 'none' } } >
          Manage Events
        </Button>

        <Dialog
          open={this.state.openRemoveConfirmation}
          onClose={this.handleCloseRemoveConfirmation}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Remove event"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to remove this event?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseRemoveConfirmationCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleCloseRemoveConfirmationOk} color="secondary" autoFocus>
              Remove
            </Button>
          </DialogActions>
        </Dialog>
        
      </Container>
    );
  }

}

export default withStyles(useStyles)(MyEvents);
