import React from "react";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';

const useStyles = theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 400,
    margin: `${theme.spacing(1)}px auto`,
  },
  wrapperButtonLoading: {
    position: 'relative',
  },
  buttonProgress: {
    color: '#d45b07',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  myContent: {
    //backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6, 0, 6),
  },
});

class Forgot extends React.Component {
  
  constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
      email: '',
      password: '',
      confirmPassword: '',
      openSnack: false,
      messageSnack: "",
      severitySnack: "success",
      toConfirm: false
		};
  }

	async componentDidMount() {
		if (this.props.isAuthenticated) {
			this.props.history.push('/');
		}else{
      const data = await Auth.currentAuthenticatedUser();
      if (data.attributes)
        this.props.history.push('/');
    }
	}
  
	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	};

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });
    Auth.forgotPassword(this.state.email)
    .then(data => {
        this.setState({ isLoading: false, toConfirm: true })
        console.log(data)}
    )
    .catch(err => console.log(err));
  }

  handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({  openSnack: false });
  };


	validateForm() {
    let message = "";
    if (this.state.password.length < 8 || this.state.password.length > 50 || this.state.password !== this.state.confirmPassword){
      message += "Password doesn't match password confirmation.\n ";
    }
    this.setState({ messageSnack: message });
		return (
      this.state.password.length > 8 &&
      this.state.password.length <= 50 &&
			this.state.password === this.state.confirmPassword
		);
	}

  handleVerificationSubmit = async event => {
    event.preventDefault();
    if (this.validateForm()){
      this.setState({ isLoading: true });
      try {
        await Auth.forgotPasswordSubmit(this.state.email, this.state.verificationCode, this.state.password);
        this.props.history.push('/login');
      } catch (e) {
        this.setState({ isLoading: false, openSnack: true, messageSnack: e.message, severitySnack: "error" });
      }
    }else{
      this.setState({ openSnack: true, severitySnack: "error" });
    }
	};

  renderVerificationForm() {

    const { classes } = this.props;

    return (
        
      <form autoComplete="off" onSubmit={this.handleVerificationSubmit} validate="true">
      <Grid item className={classes.root} container xs={6} spacing={3} >
        <Grid item xs={12}>
          <Typography variant="h5" component="h1" gutterBottom>
            New password
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            Please check your email for the code.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
              fullWidth
              required
              id="verificationCode"
              type="number"
              label="Verification Code"
              placeholder="Verification Code"
              helperText=""
              onChange={this.handleChange}
            />
        </Grid>
        <Grid item xs={12}>
          <TextField
              fullWidth
              required
              id="password"
              type="password"
              label="Password"
              placeholder="Password"
              helperText=""
              onChange={this.handleChange}
            />
        </Grid>
        <Grid item xs={12}>
          <TextField
              fullWidth
              required
              id="confirmPassword"
              type="password"
              label="Confirm Password"
              placeholder="Confirm Password"
              helperText=""
              onChange={this.handleChange}
            />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.wrapperButtonLoading}>
            <Button variant="contained" color="secondary" type="submit" disabled={this.state.isLoading} fullWidth={true} > Verify </Button>
            {this.state.isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </Grid>
      </Grid>

      <Snackbar open={this.state.openSnack} autoHideDuration={6000} onClose={this.handleCloseSnack} anchorOrigin={{ vertical:"top", horizontal: "center" }}>
        <Alert onClose={this.handleCloseSnack} severity={this.state.severitySnack}>
          {this.state.messageSnack}
        </Alert>
      </Snackbar>

      </form>

    )
  }


  renderForm() {
    const { classes } = this.props;
    return(
        <Container className={classes.myContent}>
        <form autoComplete="off" onSubmit={this.handleSubmit} validate="true">
            <Grid item className={classes.root} container xs={6} spacing={3} >
              <Grid item xs={12}>
                <Typography variant="h5" component="h1" gutterBottom>
                  Forgot your password?
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                    fullWidth
                    required
                    id="email"
                    type="email"
                    label="Email address"
                    placeholder="Email address"
                    helperText=""
                    onChange={this.handleChange}
                  />
              </Grid>
              <Grid item xs={12}>
                <div className={classes.wrapperButtonLoading}>
                  <Button variant="contained" color="secondary" type="submit" disabled={this.state.isLoading} fullWidth={true} > Reset Password </Button>
                  {this.state.isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </Grid>
            </Grid>
            <Snackbar open={this.state.openSnack} autoHideDuration={5000} onClose={this.handleCloseSnack} anchorOrigin={{ vertical:"top", horizontal: "center" }}>
              <Alert onClose={this.handleCloseSnack} severity={this.state.severitySnack}>
                {this.state.messageSnack}
              </Alert>
            </Snackbar>
        </form>
      </Container>
    )
  };

  render(){
    const { classes } = this.props;
    return (
      <Container className={classes.myContent}>
        <Grid className={classes.root} container xs={6}>
          <Paper>
            {this.state.toConfirm === false ? this.renderForm() : this.renderVerificationForm()}
          </Paper>
        </Grid>
      </Container>
    );
  }

}

export default withStyles(useStyles)(Forgot);