import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { API, Auth, Storage } from 'aws-amplify';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';

import Moment from 'react-moment';

import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import "./map.css";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2,0),
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  container: {
    maxHeight: 440,
  },
  loading: {
    alignContent: 'center',
    justifyContent: 'center',
    display: 'flex',
    marginTop: theme.spacing(8),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ManageUsersEventDialog(props) {

  const classes = useStyles();

  const [loading, setLoading] = React.useState(true);
  const [users, setUsers] = useState(false);
  const [totalUrls, setTotalUrls] = useState(0);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState("");
  const [severityInfo, setSeverityInfo] = React.useState("error");
  const [values, setValues] = React.useState(false);
  const { onClose, open, selectedEvent } = props;
  const [myMarkers, setMyMarkers] = React.useState([]);
  const [position, setPosition] = React.useState([51.505, -0.09]);

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  useEffect(() => {
    if (selectedEvent!==false){
      setValues(selectedEvent);
      fetchUsers();
    }
  }, [selectedEvent]);
  
  const fetchUsers = async () => {
    try {
      let apiName = 'Events';
      let path = 'manage/events/users/'+selectedEvent.event_id; 
      let myInit = { 
          headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }, 
          response: true, 
      }
      API.get(apiName, path, myInit).then(response => {
        let markers = [];
        for (let i = 0; i < response.data.users.length; i++) {
          //console.log(response.data.users[i]);
          if (response.data.users[i].user_latitude && response.data.users[i].user_longitude)
            markers.push({ user_id: response.data.users[i].user_id, name: response.data.users[i].user_name, email: response.data.users[i].user_email, position: [ response.data.users[i].user_latitude, response.data.users[i].user_longitude] });
        }
        if (markers.length>0){
            setPosition( [markers[markers.length-1].position[0], markers[markers.length-1].position[1]]);
        }
        setMyMarkers(markers);
        setUsers(response.data.users);
        setTotalUrls(response.data.total_urls);
        setLoading(false);
      }).catch(error => {
          console.log(error.response);
      });
    } catch (err) { 
      console.log('error: ', err) 
    }
  };

  const handleClickRefresh = () => {
    fetchUsers();
  }

  const handleClickSync = async () => {
    try {
      let apiName = 'Events';
      let path = 'manage/events/sync/users/'+selectedEvent.event_id; 
      let myInit = { 
          headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }, 
          response: true, 
      }
      API.get(apiName, path, myInit).then(response => {

        let markers = [];
        for (let i = 0; i < response.data.users.length; i++) {
          //console.log(response.data.users[i]);
          if (response.data.users[i].user_latitude && response.data.users[i].user_longitude)
          markers.push({ user_id: response.data.users[i].user_id, name: response.data.users[i].user_name, email: response.data.users[i].user_email, position: [ response.data.users[i].user_latitude, response.data.users[i].user_longitude] });
        }
        if (markers.length>0){
          setPosition( [markers[markers.length-1].position[0], markers[markers.length-1].position[1]]);
        }
        setMyMarkers(markers);

        setUsers(response.data.users);
        setTotalUrls(response.data.total_urls);
        setLoading(false);
      }).catch(error => {
          console.log(error.response);
      });
    } catch (err) { 
      console.log('error: ', err) 
    }
  }

  const handleClose = () => {
    setLoading(true);
    setUsers(false);
    onClose(false);
  };

  const onChangeFile = e => {
    const file = e.target.files[0];
    Storage.put(selectedEvent.event_id+'.csv', file, {
      level: 'private',
      metadata: { event_id: String(selectedEvent.event_id) },
      contentType: 'application/csv'
    })
    .then (result => {
      console.log(result);
      setSeverityInfo('success')
      setMessageInfo("File was loaded: " + result['key']);
      setOpenAlert(true);
      setTimeout(() => { fetchUsers(); }, 2500);
      setTimeout(() => { fetchUsers(); }, 5000);
    })
    .catch(err => {
      console.log(err);
      setSeverityInfo('error')
      setMessageInfo(err);
      setOpenAlert(true);
    });
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Dialog fullScreen onClose={handleClose} open={open} TransitionComponent={Transition}>

      <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Event Users
            </Typography>
          </Toolbar>
        </AppBar>

      { users ? (
        <DialogContent>

          <Grid container alignItems="flex-start" spacing={2}>
            <Grid item xs={12} md={6}>

            <Typography component="h2" variant="h6" color="primary" gutterBottom>
              {values.event_name}
            </Typography>

            <Typography component="p" gutterBottom>
              Event Date: <strong><Moment format="MMM DD, YYYY - hh:mm a" date={values.event_datetime} /></strong>
            </Typography>
            <Typography component="p" gutterBottom>
              Event Location: <strong>{values.event_location}</strong>
            </Typography>
            <Typography component="p" gutterBottom>
              Event Code: <strong>{values.event_code}</strong>
            </Typography>
            
            <Divider light className={classes.divider} />

            <Typography component="h2" variant="h6" color="primary" gutterBottom>
              Event Engine
              <Button color="secondary" onClick={handleClickSync}>Sync Users</Button>
            </Typography>
          
            <Typography component="p" gutterBottom>
              Event Engine URLs loaded: <strong>{ totalUrls }</strong>
            </Typography>
            <Grid container alignItems="flex-start" spacing={2}>
                <Grid item xs={12}>
                  <input
                          type="file" accept=".csv"
                          onChange={onChangeFile}
                      />
                </Grid>
            </Grid>
          
            <Divider light className={classes.divider} />

            <Typography component="h2" variant="h6" color="primary" gutterBottom>
              Registered Users (Total <strong>{users.length}</strong>) 
              <Button color="secondary" onClick={handleClickRefresh}>Refresh</Button>
            </Typography>
        
              <Paper>
              <TableContainer className={classes.container}>
                <Table>
                  <TableHead>
                    <TableRow>
                        <TableCell key='email' align='left'>
                          Email
                        </TableCell>
                        <TableCell key='name' align='left'>
                          Name
                        </TableCell>
                        <TableCell key='event_engine' align='left'>
                          Event Engine
                        </TableCell>
                        <TableCell key='event_joined' align='left'>
                          Joined
                        </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.user_id}>
                          <TableCell align="left">{row.user_email}</TableCell>
                          <TableCell align="left">{row.user_name}</TableCell>
                          <TableCell align="left">{row.event_engine}</TableCell>
                          <TableCell align="left"><Moment format="MMM DD, YYYY - hh:mm a" date={row.event_datetime_added} /></TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={users.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Map center={position} zoom={3}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                  />
                  { myMarkers.map( marker => (
                    <Marker key={marker.user_id} position={marker.position}>
                      <Popup>Name: <strong>{marker.name}</strong><br/>E-mail: <strong>{marker.email}</strong></Popup>
                    </Marker>
                  )) }
              </Map>

            </Grid>

          </Grid>

        </DialogContent>
      ) : (
        <div className={classes.loading} style={loading ? { } : {display: 'none' } }>
          <CircularProgress />
        </div>
      )}

      <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'top' }} open={openAlert} autoHideDuration={5000} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity={severityInfo}>
            {messageInfo}
          </Alert>
      </Snackbar>

    </Dialog>
  );
}

export default ManageUsersEventDialog;
