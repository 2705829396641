import React from "react";
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { Analytics, API, Auth } from 'aws-amplify';
import CircularProgress from '@material-ui/core/CircularProgress';
import { geolocated } from "react-geolocated";
import Hidden from '@material-ui/core/Hidden';
import Moment from 'react-moment';
import { green } from '@material-ui/core/colors';
import CssBaseline from '@material-ui/core/CssBaseline';
import CloudDoneOutlinedIcon from '@material-ui/icons/CloudDoneOutlined';

import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Markdown from '../utils/Markdown';

import AddIcon from '@material-ui/icons/Add';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';

const useStyles = theme => ({
  loading: {
    alignContent: 'center',
    justifyContent: 'center',
    display: 'flex',
    margin: theme.spacing(8),
  },
  wrapperButtonLoading: {
    position: 'relative',
  },
  buttonProgress: {
    color: '#d45b07',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  mainFeaturedEvent: {
    color: "#ffffff",
    padding: theme.spacing(8,0,2,0),
  },
  mainFeaturedEventDetail: {
    padding: theme.spacing(2,0,8,0),
  },
  mainGrid: {
    marginTop: theme.spacing(2),
  },

  event_analytics: {
    background: 'url("/style/img/back_event.png") no-repeat bottom right, linear-gradient(45deg, #aa076b 30%, #61045f 90%)',
  },

  event_ar_vr: {
    background: 'url("/style/img/back_event.png") no-repeat bottom right, linear-gradient(45deg, #36d1dc 30%, #5b86e5 90%)',
  },

  event_blockchain: {
    background: 'url("/style/img/back_event.png") no-repeat bottom right, linear-gradient(45deg, #4568dc 30%, #b06ab3 90%)',
  },

  event_communications_applications: {
    background: 'url("/style/img/back_event.png") no-repeat bottom right, linear-gradient(45deg, #43cea2 30%, #185a9d 90%)',
  },

  event_compute: {
    background: 'url("/style/img/back_event.png") no-repeat bottom right, linear-gradient(45deg, #7b4397 30%, #dc2430 90%)',
  },

  event_containers: {
    background: 'url("/style/img/back_event.png") no-repeat bottom right, linear-gradient(45deg, #2E3192 30%, #1BFFFF 90%)',
  },

  event_containers_microservices: {
    background: 'url("/style/img/back_event.png") no-repeat bottom right, linear-gradient(45deg, #02aab0 30%, #00cdac 90%)',
  },

  event_customer_engagement: {
    background: 'url("/style/img/back_event.png") no-repeat bottom right, linear-gradient(45deg, #614385 30%, #516395 90%)',
  },

  event_database: {
    background: 'url("/style/img/back_event.png") no-repeat bottom right, linear-gradient(45deg, #56ab2f 30%, #a8e063 90%)',
  },

  event_dev_tools: {
    background: 'url("/style/img/back_event.png") no-repeat bottom right, linear-gradient(45deg, #06beb6 30%, #48b1bf 90%)',
  },

  event_edge_services: {
    background: 'url("/style/img/back_event.png") no-repeat bottom right, linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
  },

  event_end_user_compute: {
    background: 'url("/style/img/back_event.png") no-repeat bottom right, linear-gradient(45deg, #aa076b 30%, #61045f 90%)',
  },

  event_enterprise_apps: {
    background: 'url("/style/img/back_event.png") no-repeat bottom right, linear-gradient(45deg, #02AABD 30%, #00CDAC 90%)',
  },

  event_general: {
    background: 'url("/style/img/back_event.png") no-repeat bottom right, linear-gradient(45deg, #56ab2f 30%, #a8e063 90%)',
  },

  event_iot: {
    background: 'url("/style/img/back_event.png") no-repeat bottom right, linear-gradient(45deg, #4568dc 30%, #b06ab3 90%)',
  },

  event_machine_learning: {
    background: 'url("/style/img/back_event.png") no-repeat bottom right, linear-gradient(45deg, #11998E 30%, #38EF7D 90%)',
  },

  event_media_services: {
    background: 'url("/style/img/back_event.png") no-repeat bottom right, linear-gradient(45deg, #7b4397 30%, #dc2430 90%)',
  },

  event_mobile: {
    background: 'url("/style/img/back_event.png") no-repeat bottom right, linear-gradient(45deg, #662D8C 30%, #ED1E79 90%)',
  },

  event_networking: {
    background: 'url("/style/img/back_event.png") no-repeat bottom right, linear-gradient(45deg, #02aab0 30%, #00cdac 90%)',
  },

  event_robotics: {
    background: 'url("/style/img/back_event.png") no-repeat bottom right, linear-gradient(45deg, #614385 30%, #516395 90%)',
  },

  event_satellite: {
    background: 'url("/style/img/back_event.png") no-repeat bottom right, linear-gradient(45deg, #56ab2f 30%, #a8e063 90%)',
  },

  event_security: {
    background: 'url("/style/img/back_event.png") no-repeat bottom right, linear-gradient(45deg, #764BA2 30%, #667EEA 90%)',
  },

  event_serverless: {
    background: 'url("/style/img/back_event.png") no-repeat bottom right, linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
  },

  event_storage: {
    background: 'url("/style/img/back_event.png") no-repeat bottom right, linear-gradient(45deg, #aa076b 30%, #61045f 90%)',
  },

  event_web_sites_web_apps: {
    background: 'url("/style/img/back_event.png") no-repeat bottom right, linear-gradient(45deg, #36d1dc 30%, #5b86e5 90%)',
  },

  sidebarAboutBox: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.grey[100],
  },
  aboutBox: {
    padding: theme.spacing(0,4)
  },
  eventEngineButton: {
    alignContent: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    display: 'flex',
    marginTop: theme.spacing(4),
  },
  markdown: {
    ...theme.typography.body2,
    padding: theme.spacing(3, 0),
  },
});

class Event extends React.Component {

  constructor(props) {
      super(props);
      if (this.props.match.params) {
        this.props.setToEvent(this.props.match.params.event_code);
      }
      this.state = {
        eventAdded: false,
        isLoadingAdd: false,
        isLoadingPage: true,
        isLoadingPageUser: true,
        event_code: "",
        event_datetime: "",
        event_description: "",
        event_short_description: "",
        event_id: 0,
        event_location: "",
        event_url_location: "",
        event_tech_depth: "",
        event_name: "",
        event_time_zone: "",
        event_engine: null,
        event_open: false
      };
  }

  async componentDidMount() {
    
    let userInfo = false;
    let token = "";
    try {
      if (await Auth.currentSession()) {
        const data = await Auth.currentAuthenticatedUser();
        userInfo = data.attributes;
        token = `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`;
      }
    }catch(e){
      console.log(e);
    }

    if (this.props.match.params) {
      //console.log(this.props.match.params.event_code);
      let apiName = 'Events';
      let path1 = 'event/'+this.props.match.params.event_code;
      let myInit1 = { 
          response: true,
      }
      API.get(apiName, path1, myInit1).then(response => {
          //console.log(response);
          let event_tech_depth = "General";
          if ("event_tech_depth" in response.data) {
            event_tech_depth = response.data.event_tech_depth;
          }
          this.setState({
            event_code: response.data.event_code,
            event_datetime: response.data.event_datetime,
            event_short_description: response.data.event_short_description,
            event_description: response.data.event_description,
            event_id: response.data.event_id,
            event_location: response.data.event_location,
            event_url_location: response.data.event_url_location,
            event_name: response.data.event_name,
            event_time_zone: response.data.event_time_zone,
            event_open: response.data.event_open,
            event_tech_depth: event_tech_depth,
            isLoadingPage: false,
          });

          if (userInfo){
            let path2 = 'events/get/'+userInfo.sub+'/'+response.data.event_id;
            let myInit2 = { 
                headers: { Authorization: token }, 
                response: true,
            }
            API.get(apiName, path2, myInit2).then(response => {
                this.setState({ eventAdded: true, event_engine: response.data.event_engine, isLoadingPageUser: false });
            }).catch(error => {
                this.setState({ isLoadingPageUser: false });
            });
          }else{
            this.setState({ isLoadingPageUser: false });
          }
      }).catch(error => {
          console.log(error);
          this.props.history.push('/');
      });

    }
  }
  
	handleClickAdd = async event => {
    if (this.props.userInfo!=null){
      let location = { };
      let latitude = null;
      let longitude = null;
      if (this.props.coords){
        location = { latitude: this.props.coords.latitude.toFixed(1), longitude: this.props.coords.longitude.toFixed(1) }
        latitude = this.props.coords.latitude;
        longitude = this.props.coords.longitude;
      }

      let userInfo = false;
      let user_name = "";
      try {
        if (await Auth.currentSession()) {
          const data = await Auth.currentAuthenticatedUser();
          userInfo = data.attributes;
          userInfo.name ? user_name = userInfo.name : user_name = userInfo.email;
        }
      }catch(e){
        console.log(e);
      }

      this.setState({isLoadingAdd: true});

      let apiName = 'Events';
      let path = 'events/add';
      let myInit = {
          headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }, 
          body: {
            event_code: this.state.event_code,
            user_id: this.props.userInfo.sub,
            user_email: this.props.userInfo.email,
            user_name: this.props.userInfo.name,
            user_latitude: latitude,
            user_longitude: longitude
          },
          response: true,
      }
      API.post(apiName, path, myInit).then(response => {
          this.setState({ eventAdded: true, isLoadingAdd: false });
          let event_attributes = { 
            event_code: this.state.event_code, 
            event_id: this.state.event_id+"", 
            event_name: this.state.event_name,
            event_location: this.state.event_location,
            event_datetime: this.state.event_datetime+"",
            event_time_zone: this.state.event_time_zone,
            event_tech_depth: this.state.event_tech_depth
          };
          console.log(event_attributes);

          Analytics.record({
            name: 'eventAdded', 
            attributes: event_attributes
          }).then( (evt) => {
            console.log("Event Submitted" + JSON.stringify(evt));
          });
          
          if (userInfo){
            Analytics.updateEndpoint({
              address: userInfo.email,
              channelType: 'EMAIL',
              optOut: 'NONE',
              userId: userInfo.sub,
              location,
              attributes: {
                lastEventNameAdded: [this.state.event_name],
                lastEventCodeAdded: [this.state.event_code],
                lastEventTypeAdded: [this.state.event_tech_depth]
              },
              userAttributes: {
                Name: [user_name],
                FirstName: [user_name],
                LastName: [user_name],
                PhoneNumber: [userInfo.phone_number]
              }
            }).then((evt) => {
              //console.log("Update Submitted" + JSON.stringify(evt));
            });
          }

      }).catch(error => {
          console.log(error.response);
          this.setState({isLoadingAdd: false});
      });

    }
	};

  render() {

    const { classes } = this.props;

    return (
      <div>
        <CssBaseline />
        {!this.state.isLoadingPage ? (
          <div>
            <div className={eval('classes.event_'+this.state.event_tech_depth.replace(/ /g, "_").replace(/\//g,'_').toLowerCase())}>
            <Container maxWidth="md" >
              <main>

                      <div className={classes.mainFeaturedEvent}>
                        <Typography component="h1" variant="h4" color="inherit" align="center" >
                          {this.state.event_name}
                        </Typography>
                      </div>
                    
                      <div className={classes.mainFeaturedEventDetail}>
                      {!this.props.isAuthenticated && (
                        <Grid container spacing={2} justify="center">
                          <Grid item>
                            <Button variant="contained" color="secondary" component={Link} to={ "/login" }>
                              Sign In
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button variant="contained" color="primary" component={Link} to={ "/signup" }>
                              Create Account
                            </Button>
                          </Grid>
                        </Grid>
                      )}

                      {(!this.state.isLoadingPageUser && this.props.isAuthenticated) && (
                        <Grid container spacing={2} justify="center">
                          <Grid item>
                            {(!this.state.eventAdded && this.state.event_open) && (
                              <div className={classes.wrapperButtonLoading}>
                                <Button variant="contained" color="secondary" onClick={this.handleClickAdd} disabled={this.state.isLoadingAdd}>
                                  <AddIcon className={classes.extendedIcon} /> &nbsp;&nbsp;Add to my Events
                                </Button>  
                                {this.state.isLoadingAdd && <CircularProgress size={24} className={classes.buttonProgress} />}
                              </div>
                            )}
                            {this.state.eventAdded && (
                              <Button component={Link} to={'/my-events'} variant="contained" color="secondary">
                                <EventAvailableIcon className={classes.extendedIcon} /> &nbsp;&nbsp;Already in your Events!
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                      )}
                      </div>
                    
                </main>
            </Container>
            </div>

            <Container maxWidth="md">
              <main>

                { this.state.event_engine!=null && (
                  <Typography>
                    <Button color="primary" variant="outlined" className={classes.eventEngineButton} component="a" href={ this.state.event_engine } target="_blank" rel="noopener noreferrer" startIcon={<CloudDoneOutlinedIcon />}>Access to AWS Console (Event Engine)</Button>
                  </Typography>
                )}

                <Grid container spacing={5} className={classes.mainGrid}>
                  <Grid item xs={12} md={8}>
                    <Paper className={classes.aboutBox}>
                      {this.state.event_description && (
                      <Markdown className={classes.markdown} key={this.state.event_description.substring(0, 40)}>
                        {this.state.event_description}
                      </Markdown>
                      )}
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Paper elevation={0} className={classes.sidebarAboutBox}>
                      <Typography variant="h6">
                        Date
                      </Typography>
                      <Divider />
                      <Typography variant="body1" paragraph>
                        <Moment format="MMM DD, YYYY - hh:mm a" date={this.state.event_datetime} /><br/>
                        <small>{this.state.event_time_zone}</small>
                      </Typography>
                      <Typography variant="h6">
                        Where
                      </Typography>
                      <Divider />
                      <Typography variant="body1" paragraph>
                        {this.state.event_url_location ? (
                          <a target="_blank" rel="noopener noreferrer" href={this.state.event_url_location} className="my_link" color="primary">{this.state.event_location}</a>
                        ):(
                          <span>{this.state.event_location}</span>
                        )}
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>
              </main>
            </Container>
          </div>
        ):(
            <div className={classes.loading}>
              <CircularProgress />
            </div>
        )}

        <Hidden only={['xs', 'sm', 'md', 'lg', 'xl']}>
          {!this.props.isGeolocationAvailable ? (
            <div>Your browser does not support Geolocation</div>
        ) : !this.props.isGeolocationEnabled ? (
            <div>Geolocation is not enabled</div>
        ) : this.props.coords ? (
            <table>
                <tbody>
                    <tr>
                        <td>latitude</td>
                        <td>{this.props.coords.latitude}</td>
                    </tr>
                    <tr>
                        <td>longitude</td>
                        <td>{this.props.coords.longitude}</td>
                    </tr>
                    <tr>
                        <td>altitude</td>
                        <td>{this.props.coords.altitude}</td>
                    </tr>
                    <tr>
                        <td>heading</td>
                        <td>{this.props.coords.heading}</td>
                    </tr>
                    <tr>
                        <td>speed</td>
                        <td>{this.props.coords.speed}</td>
                    </tr>
                </tbody>
            </table>
        ) : (
            <div>Getting the location data&hellip; </div>
        )

          }
        </Hidden>

      </div>
    );
  }
}

export default withStyles(useStyles)(geolocated({
  positionOptions: {
      enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(Event));
