import React from "react";
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { Analytics, Auth } from 'aws-amplify';

const useStyles = theme => ({
  myButtons: {
    marginTop: theme.spacing(4),
  },
  button: {
    margin: theme.spacing(1),
  },
  myContent: {
    //backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6, 0, 6),
  },
});

class Home extends React.Component {

	/*constructor(props) {
		super(props);
  }*/
  
	async componentDidMount() {

    let userInfo = false;
    try {
      if (await Auth.currentSession()) {
        const data = await Auth.currentUserInfo();
        userInfo = data.attributes;
        let user_name = "";
        userInfo.name ? user_name = userInfo.name : user_name = userInfo.email;
        Analytics.updateEndpoint({
          address: userInfo.email,
          channelType: 'EMAIL',
          optOut: 'NONE',
          userId: userInfo.sub,
          userAttributes: {
            Name: [user_name],
            FirstName: [user_name],
            LastName: [user_name],
            PhoneNumber: [userInfo.phone_number]
          }
        }).then(() => {
        });
      }
    }catch(e){
      console.log(e);
    }
	}

  render() {
    
    const { classes } = this.props;

    return (
      <Container className={classes.myContent}>
        <Container maxWidth="sm" >
          <Typography component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
            Welcome to AWS Events
          </Typography>
          <Typography variant="h5" align="center" color="textSecondary" paragraph >
            This is an unofficial site to join and share information related to events.
          </Typography>
        </Container>
        <div className={classes.myButtons}>
          {!this.props.isAuthenticated ?(
            <Grid container spacing={2} justify="center">
              <Grid item>
                <Button variant="contained" color="secondary" component={Link} to={ "/login" }>
                  Sign In
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" component={Link} to={ "/signup" }>
                  Create Account
                </Button>
              </Grid>
            </Grid>
          ): (
            <Grid container spacing={2} justify="center">
              <Grid item>
                <Button variant="contained" color="secondary" component={Link} to={ "/my-events" }>
                  Go to my Events
                </Button>
              </Grid>
            </Grid>
          )}
        </div>
      </Container>
    );
  }
}

export default withStyles(useStyles)(Home);
