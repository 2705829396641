/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:c8bfb877-6113-43c2-9cf2-514013790e22",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_lWNoyD4qT",
    "aws_user_pools_web_client_id": "189fbpb0hsc01nosrfb4jcdgkj",
    "oauth": {},
    "aws_mobile_analytics_app_id": "3f73b1d6b36047ce994d93198c589fc0",
    "aws_mobile_analytics_app_region": "us-east-1",
    "aws_content_delivery_bucket": "eventsawskabitscom-20200518131818-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d28gf0qesg3fd2.cloudfront.net"
};


export default awsmobile;
