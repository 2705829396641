import React from "react";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { Link } from "react-router-dom";
import Paper from '@material-ui/core/Paper';

const useStyles = theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 400,
    margin: `${theme.spacing(1)}px auto`,
  },
  wrapperButtonLoading: {
    position: 'relative',
  },
  buttonProgress: {
    color: '#d45b07',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  myContent: {
    //backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6, 0, 6),
  },
});

class Login extends React.Component {
  
  constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			email: '',
      password: '',
      openSnack: false,
      messageSnack: "",
      severitySnack: "success",
      toConfirm: false,
		};
  }

	async componentDidMount() {
		if (this.props.isAuthenticated) {
			this.props.history.push('/');
		}else{
      const data = await Auth.currentAuthenticatedUser();
      if (data.attributes)
        this.props.history.push('/');
    }
	}
  
	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	};

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });
		try {
			await Auth.signIn(this.state.email, this.state.password);
      this.props.userHasAuthenticated(true);
      if (this.props.toEvent!==""){
        this.props.history.push('/event/'+this.props.toEvent);
      }else{
        this.props.history.push('/');
      }
		} catch (e) {
      if (e.code==='UserNotConfirmedException'){
        this.setState({ isLoading: false, toConfirm: true, openSnack: true, messageSnack: e.message, severitySnack: "info"   });
      }else{
        this.setState({ isLoading: false, openSnack: true, messageSnack: e.message, severitySnack: "error" });
      }
    }
  }

  handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({  openSnack: false });
  };


  handleConfirmationSubmit = async event => {
		event.preventDefault();
		this.setState({ isLoading: true });
		try {
      await Auth.confirmSignUp(this.state.email, this.state.confirmationCode);
      this.setState({ toConfirm: false, isLoading: false, openSnack: true, messageSnack: "User confirmed.", severitySnack: "success" });
		} catch (e) {
			this.setState({ isLoading: false, openSnack: true, messageSnack: e.message, severitySnack: "error" });
		}
	};

  renderConfirmationForm() {

    const { classes } = this.props;

    return (
        
      <form autoComplete="off" onSubmit={this.handleConfirmationSubmit} validate="true">
      <Grid item className={classes.root} container xs={6} spacing={3} >
        <Grid item xs={12}>
          <Typography variant="h5" component="h1" gutterBottom>
            Confirmation Code
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            Please check your email for the code.
          </Typography>
        </Grid>
        <Grid item xs={12}>
            <TextField
                fullWidth
                required
                id="confirmationCode"
                type="number"
                label="Confirmation Code"
                placeholder="Confirmation Code"
                helperText=""
                onChange={this.handleChange}
              />
        </Grid>

        <Grid item xs={12}>
          <div className={classes.wrapperButtonLoading}>
            <Button variant="contained" color="secondary" type="submit" disabled={this.state.isLoading} fullWidth={true} > Verify </Button>
            {this.state.isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </Grid>
      </Grid>

      <Snackbar open={this.state.openSnack} autoHideDuration={6000} onClose={this.handleCloseSnack} anchorOrigin={{ vertical:"top", horizontal: "center" }}>
        <Alert onClose={this.handleCloseSnack} severity={this.state.severitySnack}>
          {this.state.messageSnack}
        </Alert>
      </Snackbar>

      </form>

    )
  }

  renderForm() {
    const { classes } = this.props;
    return(
      <form autoComplete="off" onSubmit={this.handleSubmit} validate="true">
          <Grid container className={classes.root} container xs={6} spacing={3} >
            <Grid item xs={12}>
              <Typography variant="h5" component="h1" gutterBottom>
                Sign In to AWS Events
              </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    required
                    id="email"
                    type="email"
                    label="Email address"
                    placeholder="Email address"
                    helperText=""
                    onChange={this.handleChange}
                  />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    required
                    id="password"
                    type="password"
                    label="Password"
                    placeholder="Password"
                    helperText=""
                    onChange={this.handleChange}
                  />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                  Forgot your password? <Link to={'/forgot-password'} class="my_link">Reset password</Link>
              </Typography>
              <Typography variant="body2">
                  No account? <Link to={'/signup'} class="my_link">Create account</Link>
              </Typography>
            </Grid>
  
            <Grid item xs={12}>
              <div className={classes.wrapperButtonLoading}>
                <Button variant="contained" color="secondary" type="submit" disabled={this.state.isLoading} fullWidth={true} > Sign In </Button>
                {this.state.isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div>
            </Grid>
          </Grid>

          <Snackbar open={this.state.openSnack} autoHideDuration={5000} onClose={this.handleCloseSnack} anchorOrigin={{ vertical:"top", horizontal: "center" }}>
            <Alert onClose={this.handleCloseSnack} severity={this.state.severitySnack}>
              {this.state.messageSnack}
            </Alert>
          </Snackbar>
      </form>
    )
  };

  render(){
    const { classes } = this.props;
    return (
      <Container className={classes.myContent}>
        <Grid className={classes.root} container xs={6}>
          <Paper>
            {this.state.toConfirm === false ? this.renderForm() : this.renderConfirmationForm()}
          </Paper>
        </Grid>
      </Container>
    );
  }
}

export default withStyles(useStyles)(Login);